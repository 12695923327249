import EventEmitter from '../classes/event-emitter.js';

/**
 * Class defining an Popin
 */
export default class Popin extends EventEmitter {
  /**
   * Gets the popin and attaches an eventListener to it
   * @param  {domElement} popin The popin element
   * @returns {void}
   */
  constructor(popin) {
    super();

    this.isOpen = false;
    this.parent = popin;
    this.closeBtn = popin.querySelectorAll('.js-popin-close');
    this.toggle = this.toggle.bind(this);
    this.open = this.open.bind(this);

    if (this.closeBtn.length > 0) {
      for (let i = 0; i < this.closeBtn.length; i += 1) {
        this.closeBtn[i].addEventListener('click', this.toggle);
      }
    }

    window.addEventListener('load', this.open);
  }

  /**
   * Toggles the popin and emits a toggle event
   */
  toggle() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
    this.emit('toggle', this.isOpen);
  }

  /**
   * Opens the popin pane
   */
  open() {
    this.isOpen = true;
    this.parent.classList.add('is-open');
    this.emit('open');
  }

  /**
   * Closes the popin pane
   */
  close() {
    this.isOpen = false;
    this.parent.classList.remove('is-open');
    this.emit('close');
  }
}

/**
 * Factory fucntion to instantiate all side menus.
 * @returns {Array} A list of SideMenu instances
 */
export function popinFactory() {
  const popins = Array.from(document.querySelectorAll('.popin'));
  return popins.map((element) => new Popin(element));
}
