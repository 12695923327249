// Homepage's slider

import Flickity from 'flickity';
import 'flickity-as-nav-for';

// There is two sliders, one for the text and one for the medias
/** */
export default function initFrontPage() {
  const mediaContainer = document.querySelector('.slider-media');
  const textContainer = document.querySelector('.slider-text');

  // eslint-disable-next-line
  const sliderMedia = new Flickity(mediaContainer, {
    prevNextButtons: false,
    pageDots: false,
    asNavFor: textContainer,
  });

  const sliderText = new Flickity(textContainer, {
    autoPlay: 5000,
    asNavFor: mediaContainer,
    pauseAutoPlayOnHover: true,
  });

  // Flickity stops the autoplay if we interact with the slider, so we reenable it if it's the case
  // eslint-disable-next-line func-names
  sliderText.on('change', function () {
    if (this.player.state === 'stopped') {
      this.player.play();
    }
  });

  /* Since the media slider can't have an autoplay, we want tough pause both sliders on its hover and play otherwise */

  mediaContainer.addEventListener('mouseover', () => {
    sliderText.player.stop();
  });

  mediaContainer.addEventListener('mouseleave', () => {
    if (sliderText.player.state === 'stopped') {
      sliderText.player.play();
    }
  });

  // We don't need the text slider's controls if there is just one slide

  const sliderTextDots = textContainer.querySelector('.flickity-page-dots');
  const sliderTextArrows = textContainer.querySelectorAll('.flickity-button');

  if (sliderText.cells.length === 1) {
    sliderTextDots.style.display = 'none';

    sliderTextArrows.forEach((arrow) => {
      arrow.style.display = 'none';
    });
  }
}
