/**
 * Class to emit events
 */
export default class EventEmitter {
  /**
   * Creates the events object
   * @returns {Void}
   */
  constructor() {
    this.events = {};
  }

  /**
   * Adds the event and the listener to the events object
   * @param  {string} event The event we want to attach
   * @param  {string} listener The element on which the event is attached to
   */
  on(event, listener) {
    if (typeof this.events[event] !== 'object') {
      this.events[event] = [];
    }

    this.events[event].push(listener);
  }

  /**
   * Removes the event and the listener from the events object
   * @param  {string} event    The event we want to remove
   * @param  {string} listener The element on which the event was attached to
   */
  off(event, listener) {
    let index;

    if (typeof this.events[event] === 'object') {
      index = this.events[event].indexOf(listener);

      if (index > -1) {
        this.events[event].splice(index, 1);
      }
    }
  }

  /**
   * Emits an event
   * @param  {string} event The event to emit
   * @param  {arguments} args
   */
  emit(event, ...args) {
    let listeners;

    if (typeof this.events[event] === 'object') {
      listeners = this.events[event].slice();

      listeners.forEach((listener) => {
        listener.apply(this, args);
      });
    }
  }

  /**
   * Emits an event once and then removes it
   * @param  {string} event    The event to emit
   * @param  {string} listener The listener on which the event was attached to
   * @param  {arguments} args
   */
  once(event, listener, ...args) {
    this.on(event, function g() {
      this.removeListener(event, g);
      listener.apply(this, args);
    });
  }
}
