import { Base } from '@studiometa/js-toolkit';

/**
 * Accordion component.
 */
export default class RandomizeIcon extends Base {
  /**
   * Component Configuration
   *
   */
  static config = {
    name: 'RandomizeIcon',
    refs: ['icons[]', 'exclude'],
  };

  /**
   *
   */
  mounted() {
    this.randomizeIcons();
    const excludeRect = this.$refs.exclude.getBoundingClientRect();

    this.$refs.icons.forEach((icon) => {
      // If the icon is within the exclude element, reset its position
      if (
        icon.getBoundingClientRect().left >= excludeRect.left &&
        icon.getBoundingClientRect().top >= excludeRect.top &&
        icon.getBoundingClientRect().right <= excludeRect.right &&
        icon.getBoundingClientRect().bottom <= excludeRect.bottom
      ) {
        icon.style.left = ''; // Reset left position
        icon.style.top = ''; // Reset top position
      }
    });
  }

  /**
   *
   */
  randomizeIcons() {
    this.$refs.icons.forEach((icon) => {
      // Randomize position
      const randomX = Math.floor(Math.random() * window.innerWidth);
      const randomY = Math.floor(Math.random() * window.innerHeight);

      // Set position
      icon.style.left = `${randomX}px`;
      icon.style.top = `${randomY}px`;
    });
  }
}
