/**
 * Side menu component.
 * @see templates/components/side-menu.twig
 * @see src/scss/components/_side-menu.scss
 */
export default class SideMenu {
  /**
   * Class constructor.
   * Set all needed properties.
   * @param {HTMLElement} element The root element of the component
   */
  constructor(element) {
    this.el = element;
    this.btnOpen = this.el.querySelector('.side-menu__btn--open');
    this.btnClose = this.el.querySelector('.side-menu__btn--close');
    this.body = document.body;

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  /**
   * Bind all events.
   * @returns {SideMenu} The current instance
   */
  init() {
    this.body.addEventListener('click', (e) => {
      if (e.target !== this.el && !this.el.contains(e.target)) {
        this.close();
      }
    });
    this.btnOpen.addEventListener('click', this.open);
    this.btnClose.addEventListener('click', this.close);
    return this;
  }

  /**
   * Unbind all events
   * @returns {SideMenu} The current instance
   */
  destroy() {
    this.btnOpen.removeEventListener('click', this.open);
    this.btnClose.removeEventListener('click', this.close);
    return this;
  }

  /**
   * Open the side menu
   * @returns {void}
   */
  open() {
    this.el.classList.add('is-open');
    this.btnClose.classList.add('fixed');
    this.body.classList.add('overflow-y-hidden');
  }

  /**
   * Close the side menu
   * @returns {void}
   */
  close() {
    this.el.classList.remove('is-open');
    this.btnClose.classList.remove('fixed');
    this.body.classList.remove('overflow-y-hidden');
  }
}

/**
 * Factory fucntion to instantiate all side menus.
 * @returns {Array} A list of SideMenu instances
 */
export function sideMenuFactory() {
  const sideMenus = Array.from(document.querySelectorAll('.side-menu'));
  return sideMenus.map((element) => new SideMenu(element).init());
}
