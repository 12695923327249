/**
 * Class ScrollTo
 */
export default class ScrollTo {
  /**
   * Class constructor.
   * Set all needed properties.
   * @param {HTMLElement} element The element where the event start
   */
  constructor(element) {
    this.el = element;
  }

  /**
   * Bind all events.
   */
  init() {
    this.el.addEventListener('click', this.launchScroll);
  }

  /**
   * Scroll to the target.
   * @param {Event} e
   */
  launchScroll(e) {
    this.target = document.querySelector(`#${e.target.dataset.target}`);
    this.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}

/**
 * Factory function to instantiate all elements.
 * @returns {Array} A list of scrollToElement instances
 */
export function scrollToFactory() {
  const scrollToElement = Array.from(document.querySelectorAll('[data-target]'));
  return scrollToElement.map((element) => new ScrollTo(element).init());
}
