import { Base, createApp } from '@studiometa/js-toolkit';
import { ScrollAnimation } from '@studiometa/ui';
import { sideMenuFactory } from './components/_side-menu.js';
import { popinFactory } from './components/_popin.js';
import { scrollToFactory } from './components/_scrollTo.js';
import './components/_accordion.js';
import initFrontPage from './pages/front-page.js';
import Parallax from './components/Parallax.js';
import RandomizeIcon from './components/RandomizeIcon.js';
import MagneticCursor from './components/MagneticCursor.js';
import Loader from './components/Loader.js';

/**
 * Main App Class
 */
class App extends Base {
  /**
   * App Config
   */
  static config = {
    name: 'App',
    components: {
      Parallax,
      ScrollAnimation,
      RandomizeIcon,
      MagneticCursor,
      Loader,
    },
  };

  isReady = false;

  /**
   * Log a nice message when app is ready.
   * @returns {void}
   */
  mounted() {
    // eslint ignore no-return-assign
    window.addEventListener('load', () => {
      // this.isLoaded = true;
    });
    this.isReady = true;

    scrollToFactory();
    sideMenuFactory();
    popinFactory();
    initFrontPage();
  }
}

export default createApp(App);
