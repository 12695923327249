import { Base } from '@studiometa/js-toolkit';

/**
 * Accordion component.
 */
export default class MagneticCursor extends Base {
  /**
   * Component Configuration
   *
   */
  static config = {
    name: 'MagneticCursor',
    refs: ['items[]'],
    options: {
      magneticValue: { type: Number, default: 0.2 },
    },
  };

  /**
   *
   * @param {Event} e
   */
  onMousemove(e) {
    const Xvalue = e.pageX - this.$el.offsetLeft;
    const Yvalue = e.pageY - this.$el.offsetTop;
    this.$refs.items.forEach((item) => {
      item.animate(
        {
          transform: `translate(${Xvalue * 0.02}%, ${Yvalue * 0.02}%)`,
        },
        {
          duration: 500,
          fill: 'forwards',
        }
      );
    });
  }

  /**
   *
   */
  onMouseleave() {
    this.$refs.items.forEach((item) => {
      item.animate(
        {
          transform: `translate(0)`,
        },
        {
          duration: 150,
          fill: 'forwards',
        }
      );
    });
  }
}
