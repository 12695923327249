import { ScrollAnimation } from '@studiometa/ui';

/**
 * Class for a Parallax animation
 */
export default class Parallax extends ScrollAnimation {
  /**
   * @returns {Void}
   */
  get $options() {
    return {
      ...super.$options,
      from: { y: 0 },
      to: { y: [-5, '%'] },
      offset: 'start start / end end',
    };
  }
}
