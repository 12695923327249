import { Base } from '@studiometa/js-toolkit';
import { transition, toggleClass, wait } from '@studiometa/js-toolkit/utils';
import Cookies from 'js-cookie';

/**
 * Accordion component.
 */
export default class Loader extends Base {
  /**
   * Component Configuration
   *
   */
  static config = {
    name: 'Loader',
    refs: ['icons[]', 'logoContainer'],
  };

  /**
   *
   */
  async mounted() {
    const introCookie = document.cookie.match('loaded-once');

    if (introCookie === null) {
      const html = document.documentElement;
      const isLoaded = new Promise((resolve) => {
        const loadHandler = () => {
          window.removeEventListener('load', loadHandler);
          resolve();
        };

        window.addEventListener('load', loadHandler);
      });

      await Promise.all(
        this.$refs.icons.map((icon) =>
          wait(500).then(
            transition(
              icon,
              {
                active: 'transition-transform duration-500 ease-out-quad',
                to: 'scale-[5]',
              },
              'keep'
            )
          )
        )
      );

      toggleClass(this.$el, 'bg-cream', false);
      toggleClass(this.$refs.logoContainer, 'opacity-0', true);

      await isLoaded;
      await Promise.all(
        this.$refs.icons.reverse().map((icon, index) =>
          wait(index * 100).then(
            transition(
              icon,
              {
                from: 'scale-[5]',
                active: 'transition-transform duration-500 ease-out-quad',
                to: 'scale-0',
              },
              'keep'
            )
          )
        )
      );
      Cookies.set('loaded-once', 'true');
      html.classList.remove('has-loader');
      html.classList.add('is-loaded');
      toggleClass(this.$el, 'hidden');
    }
  }
}
