// eslint-disable-next-line max-classes-per-file
import EventEmitter from '../classes/event-emitter.js';

/**
 * Class defining an Accordion
 */
class Accordion extends EventEmitter {
  /**
   * Gets the accordion and attaches an eventListener to it
   * @param  {domElement} accordion The accordion element
   * @returns {void}
   */
  constructor(accordion) {
    super();

    this.isOpen = false;
    this.parent = accordion;
    this.trigger = accordion.querySelector('.js-accordion-trigger');
    this.label = accordion.querySelector('.js-accordion-label');
    this.shouldFixScroll = accordion.dataset.scroll;

    this.trigger.addEventListener('click', () => this.toggle());
  }

  /**
   * Toggles the accordion and emits a toggle event
   */
  toggle() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
    this.emit('toggle', this.isOpen);
  }

  /**
   * Opens the accordion pane
   */
  open() {
    this.savePosition();
    this.isOpen = true;
    this.parent.classList.add('is-open');
    this.label.textContent = 'Fermer';
    this.emit('open');

    if (!this.shouldFixScroll) {
      this.resetPosition();
    }
  }

  /**
   * Closes the accordion pane
   */
  close() {
    this.savePosition();
    this.isOpen = false;
    this.parent.classList.remove('is-open');
    this.label.textContent = 'Lire la suite';
    this.emit('close');

    if (!this.shouldFixScroll) {
      this.resetPosition();
    }
  }

  /**
   * Saves the position of the element and the scroll
   */
  savePosition() {
    this.oldPos = this.trigger.getBoundingClientRect().top;
    this.oldScroll = window.scrollY;
  }

  /**
   * Resets the position of the element
   */
  resetPosition() {
    window.requestAnimationFrame(() => {
      const newPos = this.trigger.getBoundingClientRect().top;
      const newScroll = this.oldScroll - this.oldPos + newPos;

      if (newPos !== this.oldPos) {
        window.scroll(0, newScroll);
      }
    });
  }
}

/**
 *  Class to build accordions
 */
class AccordionFactory {
  /**
   * [constructor description]
   * @param  {string} selector The selector of the accordion element
   * @returns {domElement} The builded accordion
   */
  constructor(selector) {
    this.elements = [...document.querySelectorAll(selector)];

    this.accordions = this.elements.map((element, index) => {
      const accordion = new Accordion(element);

      accordion.on('open', () => {
        this.closeAllBut(index);
      });

      return accordion;
    });
  }

  /**
   * Closes all panes except the one with index
   * @param  {Int} index The index of the accordion
   */
  closeAllBut(index) {
    this.accordions.forEach((accordion, accordionIndex) => {
      if (accordionIndex === index) {
        return;
      }

      accordion.close();
    });
  }
}

export default new AccordionFactory('.accordion');
